<template>
  <div class="leftDiv">
    <div class="lTop">
      <Title TEXT="设备统计"/>
      <div class="lTop-Body">
        <div class="lTop-Body-Chart">
          <div id="pie" style="width: 110px;height: 110px"></div>
          <img src="./image/guang.png" />
        </div>

        <div class="lTop-Body-data">
          <div>
            <div class="lTop-l">
              <i class="i" style="background: #3EEF74"></i>
              <span class="span">总广播设备数</span>
            </div>

            <span class="span">{{ pieData.resourceCount }}</span>
          </div>

          <div>
            <div class="lTop-l">
              <i class="i" style="background: #FCEBEB"></i>
              <span class="span">总传感器数</span>
            </div>

            <span class="span">{{ pieData.proberCount }}</span>
          </div>

          <div>
            <div class="lTop-l">
              <i class="i" style="background: #29E6BC"></i>
              <span class="span">总摄像头数</span>
            </div>

            <span class="span">{{ pieData.cameraCount }}</span>
          </div>
        </div>
      </div>

      <div class="lMid">
        <Title TEXT="今日广播统计"/>
        <div class="lMid-Chart">
          <div class="lMid-Chart-Div" id="gbChart"></div>
        </div>
      </div>

      <div class="lBom">
        <Title TEXT="广播设备数量"/>
        <div class="lBomDiv">
          <div class="lBomDiv-top">
            <div class="lBomDiv-chart-box">
              <div class="lBomDiv-top-chart" id="lBomChart"></div>
              <img src="./image/gbsb.png" />
            </div>

            <div class="lBomDiv-top-r">
              <div class="zs">
                <div>终端总数</div>
                <div v-for="(item,index) in (Array(4).join(0) + (onlineSum + offSum + notNum)).slice(-4)" :key="index">{{item}}</div>
              </div>

              <div class="zs-list">
                <div>
                  <i class="yuan"></i>
                  <div class="midDiv">
                    <span style="font-size: 16px;font-weight: 500;color: #95D7C8">在线终端</span>
                    <i class="line"></i>
                  </div>
                  <span style="font-size: 16px;font-weight: bold;color: #3AF79F">{{ onlineSum }}</span>
                </div>

                <div>
                  <i class="yuan"></i>
                  <div class="midDiv">
                    <span style="font-size: 16px;font-weight: 500;color: #95D7C8">离线终端</span>
                    <i class="line"></i>
                  </div>
                  <span style="font-size: 16px;font-weight: bold;color: #3AF79F">{{ offSum }}</span>
                </div>

                <div>
                  <i class="yuan"></i>
                  <div class="midDiv">
                    <span style="font-size: 16px;font-weight: 500;color: #95D7C8">无回传终端</span>
                    <i class="line"></i>
                  </div>
                  <span style="font-size: 16px;font-weight: bold;color: #3AF79F">{{ notNum }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="tableDiv">
            <div class="tableHeader">
              <div>地区</div>
              <div>在线终端</div>
              <div>离线终端</div>
              <div>无回传终端</div>
            </div>
            <vue-seamless-scroll :data="numberData" class="tbody">
              <div class="tableBody" v-for="(item, index) in numberData" :key="index">
                <div>{{item.name}}</div>
                <div>{{item.onlineNum || 0}}</div>
                <div>{{item.returnNum - (item.onlineNum || 0)}}</div>
                <div>{{item.totalNum - item.returnNum}}</div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getDeviceCount } from "../../../api/jy";
import { broadcastStatisticsVO, getNumber } from "../../../api/index";
import Title from './Title'
export default {
  components:{
    Title
  },
  data(){
    return{
      pieData:{},
      numberData:[],
      onlineSum:0,
      offSum:0,
      notNum:0
    }
  },
  mounted() {
    broadcastStatisticsVO().then(res=>{
      let chartData = res.data.data.map(item=>[item.time0_4,item.time4_8,item.time8_12,item.time12_16,item.time16_20,item.time20_24])
      this.lmidInit(chartData);
    })

    getNumber().then(res=>{
      this.numberData = res.data.data;
      this.onlineSum = this.numberData.filter( s => s.onlineNum || s.onlineNum == 0).map(item=>item.onlineNum).reduce((prev, curr)=>{
        return prev + curr;
      });

      this.offSum = this.numberData.map(item=>item.returnNum - (item.onlineNum || 0)).reduce((prev, curr)=>{
        return prev + curr;
      });
      this.notNum = this.numberData.map(item=>item.totalNum - item.returnNum).reduce((prev, curr)=>{
        return prev + curr;
      });

      this.gbsbPie(this.onlineSum + this.offSum, this.onlineSum);
    })

    getDeviceCount().then(res=>{
      this.pieData = res.data.data;
      this.pieInit(res.data.data);
    })
  },
  methods:{
    gbsbPie(data, online){
      var chartDom = document.getElementById("lBomChart");
      var myChart = echarts.init(chartDom);
      let option = {
        title: [
          {
            text: `${((online / data) * 100).toFixed(1)}%`,
            left: 'center',
            top: '30%', // 大小可改
            textStyle: {
              fontSize: 18,
              color: '#fff',
            },
          },
          {
            text: '在线率',
            left: 'center',
            top: '53%', // 大小可改
            textStyle: {
              fontSize: 14,
              color: '#A8CCDE',
            },
          },
        ],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['85%', '70%'],
            silent: true,
            clockwise: true,
            startAngle: 90,
            z: 0,
            zlevel: 0,
            label: {
              normal: {
                position: "center",
              }
            },
            data: [
              {
                value: (online / data)*100,
                name: "",
                itemStyle: {
                  normal: {
                    //外环发光
                    borderWidth: 0.5,
                    shadowBlur: 10,
                    borderColor: '#4bf3f9',
                    shadowColor: '#9bfeff',
                    color: { // 圆环的颜色
                      colorStops: [{
                        offset: 0,
                        color: '#00EB74', // 0% 处的颜色
                      }, {
                        offset: 1,
                        color: '#00EB74', // 100% 处的颜色
                      }]
                    },
                  }
                }
              },
              {
                value: 100 - (online / data)*100,
                name: "",
                label: {
                  normal: {
                    show: false
                  }
                },
                itemStyle: {
                  normal: {
                    color: "#074735"
                  }
                }
              }
            ]
          },
        ]
      };
      option && myChart.setOption(option);
    },

    pieInit(data){
      var chartDom = document.getElementById("pie");
      var myChart = echarts.init(chartDom);
      var colorList = ['#00EB74', '#FCEBEB', '#29E6BC'];
      let option = {
        title: [
          {
            text: `${data.total}`,
            left: 'center',
            top: '30%', // 大小可改
            textStyle: {
              fontSize: 20,
              color: '#fff',
            },
          },
          {
            text: '总设备数',
            left: 'center',
            top: '53%', // 大小可改
            textStyle: {
              fontSize: 16,
              color: '#A8CCDE',
            },
          },
        ],
        series: [
          {
            type: 'pie',
            z: 3,
            center: ['50%', '50%'],
            radius: ['75%', '90%'],
            clockwise: true,
            avoidLabelOverlap: true,
            hoverOffset: 15,
            itemStyle: {
              normal: {
                color: function(params) {
                  return colorList[params.dataIndex]
                }
              }
            },
            label: {
              show: false
            },
            data: [
              {
                name:'总广播设备数',
                value:(data.resourceCount / data.total) * 100
              },
              {
                name:'总传感器数',
                value:(data.proberCount / data.total) * 100
              },
              {
                name:'总摄像头数',
                value: (data.cameraCount / data.total) * 100
              },
            ]
          },
          {
            type: 'pie',
            name: '外圆环',
            radius: ['98%', '100%'],
            center: ['50%', '50%'],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                color: '#29E6BC',
              },
            },
            label: {
              show: false,
            },
            data: [100],
          },
        ]
      };
      option && myChart.setOption(option);
    },

    lmidInit(data){
      var chartDom = document.getElementById("gbChart");
      var myChart = echarts.init(chartDom);
      let option = {
        tooltip: {},
        legend: {
          show: true,
          itemWidth:5,
          itemHeight:15,
          itemGap: 30,
          icon: "roundRect",
          textStyle: {
            align: 'left',
            verticalAlign: 'middle',
            rich: {
              name: {
                color: '#95D7C8',
                fontSize: 14,
              },
            },
          },
          formatter: (name) => {
              return `{name|${name}}`;
          },
        },
        grid: {
          top: '35%',
          left: '3%',
          right: '5%',
          bottom: 0,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: true,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show:true,
            color: '#90CFC1'
          },
          axisTick: {
            show: true,
          },
          boundaryGap: false,
          data: ['00-04时', '04-08时', '08-12时', '12-16时', '16-20时', '20-24时'],
        },
        yAxis: {
          type: 'value',
          min: 0,
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(24,66,56,0.5)",
              width: 16
            }
          },
          axisLabel: {
            show: true,
            color: '#90CFC1'
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: '特别重大',
            type: 'line',
            showAllSymbol: true,
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: {
              color: 'rgba(255, 60, 34, 1)'
            },
            label: {
              show: false
            },
            itemStyle: {
              color: 'rgba(255, 60, 34, 0.8)',
              borderColor: 'rgba(255, 60, 34, 0.8)',
              borderWidth: 3
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: 'rgba(255, 60, 34, 0.8)',
                      },
                      {
                        offset: 1,
                        color: 'rgba(255, 60, 34, 0)',
                      },
                    ],
                    false
                ),
                shadowColor: 'rgba(255, 60, 34, 0.8)',
                shadowBlur: 20,
              },
            },
            data: data[0],
          },

          {
            name: '重大',
            type: 'line',
            showAllSymbol: true,
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: {
              color: 'rgba(255, 120, 0, 1)'
            },
            label: {
              show: false
            },
            itemStyle: {
              color: 'rgba(255, 120, 0, 0.8)',
              borderColor: 'rgba(255, 120, 0, 0.8)',
              borderWidth: 3
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: 'rgba(255, 120, 0, 0.8)',
                      },
                      {
                        offset: 1,
                        color: 'rgba(255, 120, 0, 0)',
                      },
                    ],
                    false
                ),
                shadowColor: 'rgba(255, 120, 0, 0.8)',
                shadowBlur: 20,
              },
            },
            data: data[1],
          },

          {
            name: '较大',
            type: 'line',
            showAllSymbol: true,
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: {
              color: 'rgba(243, 225, 20, 1)'
            },
            label: {
              show: false
            },
            itemStyle: {
              color: 'rgba(243, 225, 20, 0.8)',
              borderColor: 'rgba(243, 225, 20, 0.8)',
              borderWidth: 3
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: 'rgba(243, 225, 20, 0.8)',
                      },
                      {
                        offset: 1,
                        color: 'rgba(243, 225, 20, 0)',
                      },
                    ],
                    false
                ),
                shadowColor: 'rgba(243, 225, 20, 0.8)',
                shadowBlur: 20,
              },
            },
            data: data[2],
          },

          {
            name: '一般',
            type: 'line',
            showAllSymbol: true,
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: {
              color: 'rgba(11, 229, 255, 1)'
            },
            label: {
              show: false
            },
            itemStyle: {
              color: 'rgba(11, 229, 255, 0.8)',
              borderColor: 'rgba(11, 229, 255, 0.8)',
              borderWidth: 3
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: 'rgba(11, 229, 255, 0.8)',
                      },
                      {
                        offset: 1,
                        color: 'rgba(11, 229, 255, 0)',
                      },
                    ],
                    false
                ),
                shadowColor: 'rgba(11, 229, 255, 0.8)',
                shadowBlur: 20,
              },
            },
            data: data[3],
          },

          {
            name: '未知',
            type: 'line',
            showAllSymbol: true,
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: {
              color: 'rgba(255, 255, 255, 1)'
            },
            label: {
              show: false
            },
            itemStyle: {
              color: 'rgba(255, 255, 255, 0.8)',
              borderColor: 'rgba(255, 255, 255, 0.8)',
              borderWidth: 3
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: 'rgba(255, 255, 255, 0.8)',
                      },
                      {
                        offset: 1,
                        color: 'rgba(255, 255, 255, 0)',
                      },
                    ],
                    false
                ),
                shadowColor: 'rgba(255, 255, 255, 0.8)',
                shadowBlur: 20,
              },
            },
            data: data[4],
          },
        ]
      }
      option && myChart.setOption(option);
    },
  }
}
</script>

<style scoped lang="scss">
$divWidth:396px; //统一内容框宽度
@mixin displayCenter(){
  display: flex;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes changeright {

  0% {
    -webkit-transform: rotate(0deg)
  }

  50% {
    -webkit-transform: rotate(180deg)
  }

  100% {
    -webkit-transform: rotate(360deg)
  }
}
.leftDiv{
  position: absolute;
  top: 120px;
  left: 40px;
  .lTop{
    width: $divWidth;
    .lTop-Body{
      width: 100%;
      height: 195px;
      display: flex;
      align-items: center;
      .lTop-Body-Chart{
        width: 133px;
        height: 133px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          position: absolute;
          top: 0;
          left: 0;
          width: 133px;
          height: 133px;
          animation: changeright 6s linear infinite;
        }
      }
      .lTop-Body-data{
        margin-left: 30px;
        width: 180px;
        display: flex;
        flex-direction: column;
        >div{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 35px;
          &:last-child{
            margin: 0;
          }
          .lTop-l{
            display: flex;
            align-items: center;
            .i{
              width: 16px;
              height: 16px;
              border-radius: 100%;
              margin-right: 8px;
            }
            .span{
              font-size: 16px;
              font-weight: 500;
              color: #95D7C8;
            }
          }

          .span{
            font-size: 16px;
            font-weight: bold;
            color: #3AF79F;
          }
        }
      }
    }

    .lMid{
      width: $divWidth;
      .lMid-Chart{
        width: 100%;
        height: 265px;
        @include displayCenter();
        .lMid-Chart-Div{
          height: 195px;
          width: 100%;
        }
      }
    }

    .lBom{
      width: $divWidth;
      .lBomDiv{
        width: 100%;
        .lBomDiv-top{
          width: 100%;
          height: 190px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 14px;
          .lBomDiv-chart-box{
            width: 146px;
            height: 146px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .lBomDiv-top-chart{
              width: 110px;
              height: 110px;
            }
            img{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              animation: changeright 6s linear infinite;
            }
          }

          .lBomDiv-top-r{
            width: 230px;
            .zs{
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 18px;
              font-weight: bold;
              color: #3AF79F;
              >div{
                background: #103529;
                border: 1px solid #218E5B;
                line-height: 35px;
                width: 35px;
                height: 35px;
                &:first-child{
                  height: 35px;
                  line-height: 35px;
                  width: auto;
                }
              }
            }

            .zs-list{
              margin-top: 17px;
              width: 230px;
              >div{
                margin-bottom: 16px;
                display: flex;
                align-items: center;
                &:last-child{
                  margin: 0;
                }
                .midDiv{
                  width: 160px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-right: 14px;
                  .line{
                    width: 70px;
                    border: 1px #3AF79F dashed;
                  }
                }
                .yuan{
                  width: 15px;
                  height: 15px;
                  background: #26E560;
                  border-radius: 50%;
                  margin-right: 8px;
                }
              }
            }
          }
        }

        .tableDiv{
          width: $divWidth;
          .tableHeader{
            display: flex;
            align-items: center;
            width: 100%;
            height: 36px;
            background: #133C2F;
            >div{
              width: 25%;
              text-align: center;
              font-size: 16px;
              font-weight: 500;
              color: #95D7C8;
            }
          }
          .tbody{
            position: relative;
            z-index: 9;
            height: 100px;
            overflow: hidden;
            .tableBody{
              display: flex;
              align-items: center;
              width: 100%;
              &:nth-child(2n){
                background: rgba(19, 60, 47, .4);
              }
              >div{
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow:ellipsis;
                width: 25%;
                height: 36px;
                line-height: 36px;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                color: #95D7C8;
              }
            }
          }
        }
      }
    }
  }
}
</style>
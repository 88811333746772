//map.js
// 换成本地的服务器js文件即可
let script = [
    'https://map.behomesc.com:3000/bigemap.js/v2.1.0/bigemap.js',
    "http://www.bigemap.com/Public/js/echarts.min.js",
    "http://www.bigemap.com/Public/offline/iclient/js/iclient9.min.js",
    "http://www.bigemap.com/Public/offline/marker_cluster/bm.markercluster-src.js"
];
export default new Promise(resolve => {
    let link = document.createElement("link");
    link.rel = "stylesheet";
    link.async = false;
    link.href = "https://map.behomesc.com:3000/bigemap.js/v2.1.0/bigemap.css";
    document.head.appendChild(link);

    let link2 = document.createElement("link");
    link2.rel = "stylesheet";
    link2.async = false;
    link2.href = "http://www.bigemap.com/Public/offline/marker_cluster/MarkerCluster.Default.css";
    document.head.appendChild(link2)

    let loads = script.map(v => {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.async = false;
        script.src = v;
        document.head.appendChild(script);
        return script;
    });
    let end = loads.pop();
    end.onload = resolve;
});

<template>
  <div class="mainDiv">
    <div id="map" class="myMap"></div>

    <div class="topDiv">
      <div class="k1">
        <span class="title">{{ configData.bigScreenName }}</span>
        <div class="timer">
          <time>{{ timeArr }}</time>
          <div class="riqi">
            <span>{{ monthDay }}</span>
            <span>{{ week }}</span>
          </div>
        </div>
        <div class="topRight">
          <div class="sbss" @click="isSearch =! isSearch; isDw = false ">
            <el-icon style="font-size: 20px"><Search /></el-icon>
            <span >设备搜索</span>

            <transition name="opacity">
              <div @click.stop class="dwDiv" v-show="isSearch">
                <input class="search-input" v-model="filterText" placeholder="此处输入搜索" @input="glInput"/>
                <div v-if="filterMaker.length > 0">
                  <div class="search-li" v-for="(i, index) in filterMaker" :key="index" @click="searchLoca(i)">{{ i.name }}</div>
                </div>

                <span v-else class="not">暂无设备</span>
              </div>
            </transition>
          </div>

          <div class="dw" @click="isDw =! isDw; isSearch = false">
            <el-icon style="font-size: 20px"><MapLocation /></el-icon>
            <span>区域快速定位</span>
            <transition name="opacity">
              <div @click.stop class="dwDiv" v-show="isDw">
                <el-tree default-expand-all style="width: 100%" :data="treeData" :props="defaultProps" @node-click="handleNodeClick" />
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="k2">
        <img @click="isright =! isright" style="transform:rotate(180deg)" src="./image/icon.png" />
      </div>
      <div class="k3">
        <img @click="isright =! isright" src="./image/icon.png" />
      </div>
      <div class="k4"></div>
    </div>
    <transition name="hello2">
      <div v-show="isright">
        <div class="left-shadow"></div>
        <leftDiv />
      </div>
    </transition>

    <transition name="hello">
      <div v-show="isright">
        <div class="right-shadow"></div>
        <rightDiv @EDITPOINT="editpoint" @ADDPOINT="addpoint" @DELPOINT="delpoint"/>
      </div>
    </transition>


    <bottomDiv @ADDHIDE="addhide" />

    <div class="dh">
      <div class="test" v-if="isType == 1" @click.stop>
        <div class="hed">
          <div class="hed-left">
            <div class="hedImg"></div>
            <span class="type">{{ iconData.cname || iconData.cName }}</span>
          </div>
          <div class="hed-right">
            <span v-if="isXt" style="color: red">已有喊话摄像头</span>

            <div v-if="!isXt && !isPlay && iconData.dstatus == 1" class="hh" @click="start">喊话</div>
            <div v-if="!isXt && isPlay && iconData.dstatus == 1" class="stop" @click="stop">停止喊话</div>
          </div>
        </div>

        <div class="video-play">
          <iframe allowfullscreen style="width: 100%;height: 100%" :src="iconData.serial"></iframe>
        </div>
        <div class="infoHed">摄像头类型</div>
        <div class="infoBody">{{ iconData.title }}</div>
        <div class="infoHed">摄像头型号</div>
        <div class="infoBody">{{ iconData.cmodel || iconData.cModel }}</div>
        <div class="infoHed">ip地址</div>
        <div class="infoBody">{{ iconData.ip }}</div>
        <div class="infoHed">安装地址</div>
        <div class="infoBody">{{ iconData.installAddress }}</div>
        <div class="infoHed">所属区域</div>
        <div class="infoBody">{{ iconData.regionName }}</div>
      </div>
    </div>

    <div class="dh noce">
      <div class="device-box" v-if="isType == 2" @click.stop>
        <div class="hed">
          <div class="hedImg"></div>
          <span>{{ iconData.name }}</span>
        </div>

        <div>
          <div class="infoHed">设备类型</div>
          <div class="infoBody">{{ iconData.typeName }}</div>
        </div>

        <div>
          <div class="infoHed">资源编码</div>
          <div class="infoBody">{{ iconData.code }}</div>
        </div>

        <div>
          <div class="infoHed">安装地址</div>
          <div class="infoBody">{{ iconData.installAddr || "暂无" }}</div>
        </div>

        <div>
          <div class="infoHed">所在区域</div>
          <div class="infoBody">{{ iconData.regionName }}</div>
        </div>

        <div>
          <div class="infoHed">最近在线时间</div>
          <div class="infoBody">{{ iconData.lastOnlineTime || "暂无" }}</div>
        </div>

        <div>
          <div class="infoHed">是否具有回传功能</div>
          <div class="infoBody">{{ iconData.rebackFlag ? '是' : '否' }}</div>
        </div>


        <div>
          <div class="infoHed">IP地址</div>
          <div class="infoBody">{{ iconData.ipAddr || "暂无"}}</div>
        </div>

        <div>
          <div class="infoHed">责任人</div>
          <div class="infoBody">{{ iconData.charger || "暂无" }}</div>
        </div>

        <div>
          <div class="infoHed">负责人电话</div>
          <div class="infoBody">{{ iconData.chargerPhone || "暂无" }}</div>
        </div>

        <div>
          <div class="infoHed">软件版本</div>
          <div class="infoBody">{{ iconData.softVersion || "暂无" }}</div>
        </div>

        <div>
          <div class="infoHed">硬件版本</div>
          <div class="infoBody">{{ iconData.hardVersion || "暂无" }}</div>
        </div>
      </div>
    </div>

    <div class="dh">
      <div class="device-box" v-if="isType == 3" @click.stop>
        <div class="hed">
          <div class="hedImg"></div>
          <span>{{ iconData.name }}</span>
        </div>
        <div class="infoHed">imei号</div>
        <div class="infoBody">{{ iconData.code }}</div>
        <div class="infoHed">所属区域</div>
        <div class="infoBody">{{ iconData.regionName }}</div>
        <div class="infoHed">安装地址</div>
        <div class="infoBody">{{ iconData.address }}</div>
      </div>
    </div>

    <div class="dh">
      <div class="event-box" v-if="isType == 4" @click.stop>
        <div class="event-top"></div>
        <div class="in-box">
          <div class="hed">
<!--            <img src="./child/image/737.png"/>-->
            <span>{{ iconData.name }}</span>
          </div>
          <div class="infoHed">发生时间</div>
          <div class="infoBody">{{ iconData.createTime }}</div>
          <div class="infoHed">发生区域</div>
          <div class="infoBody">{{ iconData.regionName }}</div>
          <div v-if="iconData.url">
            <div class="infoHed">告警图片</div>
            <el-image
                :append-to-body='true'
                style="width: 100%; height: 160px"
                v-if="iconData.url"
                :src="iconData.url"
                :preview-src-list="[iconData.url]"
                fit="cover"
            />
          </div>
        </div>
      </div>
    </div>

    <div @click.stop class="menu" ref="menu" v-show="isMenu">
      <div @click="onCluste">开启聚合</div>
      <div @click="offCluste">关闭聚合</div>
    </div>
  </div>
</template>
<script>
import leftDiv from './child/leftDiv';
import rightDiv from './child/rightDiv';
import bottomDiv from './child/bottomDiv'
import maps from "../../map";
import { ElMessage } from "element-plus";
import { getWeek, dateFormat } from "../../util/date";
import { gcj02towgs84 } from "../../util/util";
import { getConfig, getFlyLineOne, mapPoint, regionTree, mapPointDetail, getWebVideoDetail, stopBCast, startBCast, getPlayingBroadcastList, regionMap } from "../../api/jy";
export default {
  data(){
    return{
      isMenu: false,
      isright: true,
      minMapLevel:0,
      myEcharts: null,
      addhideList:[],
      delIcons:{
        cameraType:[],
        proberType:[],
        resourceType:[],
        warnEventType:[],
      },
      polygon:null,
      filterMaker:[],
      filterText:"",
      isSearch:false,
      markers:[],
      isXt:false,
      isPlay: false,
      isType:0,
      iconData:[],
      isDw:false,
      defaultProps:{
        children: 'children',
        label: 'name',
      },
      stationId:{},
      treeData:[],
      week:"",
      monthDay:"",
      timeArr:"",
      map:null,
      cluste: null,
      configData:{},
    }
  },

  components:{
    leftDiv,
    rightDiv,
    bottomDiv,
  },

  mounted() {
    document.addEventListener('click', (e)=> {
      this.isMenu = false;
      this.isType = 0;
    }, false)

    regionTree().then(res=>{
      this.treeData = res.data.data;
    })


    this.flyLineOneInit();
    this.delExpirePoint();


    let date = new Date();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    this.week = getWeek(date);
    let formattime = dateFormat(date, "yyyy年MM月dd日 hh:mm:ss");
    this.timeArr = formattime.split(" ")[1];
    setInterval(()=>{
      let time = new Date();
      let formattime = dateFormat(time, "yyyy年MM月dd日 hh:mm:ss");
      this.timeArr = formattime.split(" ")[1];
    },1000)
    this.monthDay = `${month}月${day}日`
  },

  methods:{
    offCluste(){

    },

    onCluste(){

    },

    delExpirePoint(){
      setInterval(()=>{
        let nowTime = Math.round(new Date() / 1000);
        let indexArr = [];
        for(let i = 0,len=this.markers.length;i<len;i++){
          if(this.markers[i].item.timestamp && this.markers[i].item.timestamp < nowTime){
            indexArr.push(i)
          }
        }

        for(let j = indexArr.length-1;j>=0;j--){
          this.markers[indexArr[j]].marker.remove();
          this.markers.splice(indexArr[j], 1);
          this.filterMaker.splice(indexArr[j], 1);
        }

        this.isType = 0;
      }, 1000 * 30);
    },

    delpoint(data){
      this.markers.filter(ite=>ite.item.code == data.code && ite.item.id == data.id)[0].marker.remove();
      this.filterMaker.splice(this.filterMaker.findIndex(i=>i.code == data.code && i.id == data.id), 1);
      this.markers.splice(this.markers.findIndex(i=>i.item.code == data.code && i.item.id == data.id), 1);
      this.isType = 0;
    },

    addpoint(data){
      let img = "";
      if(data.type == 1){
        img = require('./image/7.png');
      }else if(data.type == 2){
        if(data.code == 'soundPost' || data.code == 'intelligent'){
          if(data.status == 1){
            img = require('./image/12.png');
          }else if(data.status == 2){
            img = require('./image/12_2.png');
          }else {
            img = require('./image/12_0.png');
          }
        }else if(data.code == 'townAdapter' || data.code == 'countyAdapte'){
          if(data.status == 1){
            img = require('./image/1.png');
          }else if(data.status == 2){
            img = require('./image/1_2.png');
          }else {
            img = require('./image/1_0.png');
          }
        }else{
          if(data.status == 1){
            img = require('./image/3.png');
          }else if(data.status == 2){
            img = require('./image/3_2.png');
          }else {
            img = require('./image/3_0.png');
          }
        }
      }else if(data.type == 3){
        if(data.code == 13){
          if(data.status == 1){
            img = require('./image/4.png');
          }else {
            img = require('./image/4_0.png');
          }
        }else if(data.code == 14){
          if(data.status == 1){
            img = require('./image/5.png');
          }else {
            img = require('./image/5_0.png');
          }
        }else {
          if(data.status == 1){
            img = require('./image/6.png');
          }else {
            img = require('./image/6_0.png');
          }
        }
      }else {
        if(data.code == 1){
          img = require('./image/9.png');
        }else if(data.code == 2){
          img = require('./image/13.png');
        }else{
          img = require('./image/8.png');
        }
      }
      let location = data.location.split(',');
      let l = gcj02towgs84(location[0], location[1])
      let marker = BM.marker([l[0],l[1]],{icon:BM.icon({
          iconUrl:img,
          iconSize:[56 - (18 - this.minMapLevel) * 1.25, 89 - (18 - this.minMapLevel) * 2]})});
      this.cluste.addLayer(marker);

      marker.stationId = data; //设置参数
      marker.on('click', (e)=>{
        let { id, type } = e.target.stationId;
        this.stationId = data;
        this.getPointDetail(id, type);
      })
      this.filterMaker.push(data);
      this.markers.push({
        marker,
        item: data
      });
    },

    editpoint(data){
      // this.markers.filter(ite=>ite.item.code == data.code && ite.item.id == data.id)[0].marker.remove();
      this.cluste.removeLayer(this.markers.filter(ite=>ite.item.code == data.code && ite.item.id == data.id)[0].marker)
      let img = "";
      if(data.type == 1){
        img = require('./image/7.png');
      }else if(data.type == 2){
        if(data.code == 'soundPost' || data.code == 'intelligent'){
          if(data.status == 1){
            img = require('./image/12.png');
          }else if(data.status == 2){
            img = require('./image/12_2.png');
          }else {
            img = require('./image/12_0.png');
          }
        }else if(data.code == 'townAdapter' || data.code == 'countyAdapte'){
          if(data.status == 1){
            img = require('./image/1.png');
          }else if(data.status == 2){
            img = require('./image/1_2.png');
          }else {
            img = require('./image/1_0.png');
          }
        }else{
          if(data.status == 1){
            img = require('./image/3.png');
          }else if(data.status == 2){
            img = require('./image/3_2.png');
          }else {
            img = require('./image/3_0.png');
          }
        }
      }else if(data.type == 3){
        if(data.code == 13){
          if(data.status == 1){
            img = require('./image/4.png');
          }else {
            img = require('./image/4_0.png');
          }
        }else if(data.code == 14){
          if(data.status == 1){
            img = require('./image/5.png');
          }else {
            img = require('./image/5_0.png');
          }
        }else {
          if(data.status == 1){
            img = require('./image/6.png');
          }else {
            img = require('./image/6_0.png');
          }
        }
      }else {
        if(data.code == 1){
          img = require('./image/9.png');
        }else if(data.code == 2){
          img = require('./image/13.png');
        }else{
          img = require('./image/8.png');
        }
      }
      let location = data.location.split(',');
      let l = gcj02towgs84(location[0], location[1])
      let marker = BM.marker([l[0],l[1]],{icon:BM.icon({
          iconUrl:img,
          iconSize:[56 - (18 - this.minMapLevel) * 1.25, 89 - (18 - this.minMapLevel) * 2]})});
      this.cluste.addLayer(marker);

      marker.stationId = data; //设置参数
      marker.on('click', (e)=>{
        let { id, type } = e.target.stationId;
        this.stationId = data;
        this.getPointDetail(id, type);
      })
      this.filterMaker[this.filterMaker.findIndex(i=>i.code == data.code && i.id == data.id)] = data;
      this.markers[this.markers.findIndex(i=>i.item.code == data.code && i.item.id == data.id)] = {
        marker,
        item:data
      };
      this.isType = 0;
    },

    addhide(event, value, type){
      this.markers.forEach(item=>{
        // item['marker'].remove();
        this.cluste.removeLayer(item['marker']);
      });

      this.addhideList.forEach(item=>{
        this.cluste.removeLayer(item);
      })
      this.addhideList = [];

      let all = [];
      let delAll = [];
      let addAll = [];
      this.delIcons[type] = event;
      let camera = this.delIcons['cameraType'].length > 0 ? this.delIcons['cameraType'].map(item=>item) : [];
      let prober = this.delIcons['proberType'].length > 0 ? this.delIcons['proberType'].map(item=>item) : [];
      let resource = this.delIcons['resourceType'].length > 0 ? this.delIcons['resourceType'].map(item=>item) : [];
      let warnEvent = this.delIcons['warnEventType'].length > 0 ? this.delIcons['warnEventType'].map(item=>item) : [];

      all = [
        ...camera,
        ...prober,
        ...resource,
        ...warnEvent
      ];

      this.markers.forEach(ite=>{
        if(all.includes(ite.item.code)){
          delAll.push(ite)
        }else{
          addAll.push(ite)
        }
      })

      // delAll.forEach(i=>{
      //   i.marker.remove();
      // })
      addAll.forEach(i=>{
        let img = "";
        if(i.item.type == 1){
          img = require('./image/7.png');
        }else if(i.item.type == 2){
          if(i.item.code == 'soundPost' || i.item.code == 'intelligent'){
            if(i.item.status == 1){
              img = require('./image/12.png');
            }else if(i.item.status == 2){
              img = require('./image/12_2.png');
            }else {
              img = require('./image/12_0.png');
            }
          }else if(i.item.code == 'townAdapter' || i.item.code == 'countyAdapte'){
            if(i.item.status == 1){
              img = require('./image/1.png');
            }else if(i.item.status == 2){
              img = require('./image/1_2.png');
            }else {
              img = require('./image/1_0.png');
            }
          }else{
            if(i.item.status == 1){
              img = require('./image/3.png');
            }else if(i.item.status == 2){
              img = require('./image/3_2.png');
            }else {
              img = require('./image/3_0.png');
            }
          }
        }else if(i.item.type == 3){
          if(i.item.code == 13){
            if(i.item.status == 1){
              img = require('./image/4.png');
            }else {
              img = require('./image/4_0.png');
            }
          }else if(i.item.code == 14){
            if(i.item.status == 1){
              img = require('./image/5.png');
            }else {
              img = require('./image/5_0.png');
            }
          }else {
            if(i.item.status == 1){
              img = require('./image/6.png');
            }else {
              img = require('./image/6_0.png');
            }
          }
        }else {
          if(i.item.code == 1){
            img = require('./image/9.png');
          }else if(i.item.code == 2){
            img = require('./image/13.png');
          }else{
            img = require('./image/8.png');
          }
        }


        let location = i.item.location.split(',');
        let l = gcj02towgs84(location[0], location[1])
        let marker = BM.marker([l[0],l[1]],{icon:BM.icon({
            iconUrl:img,
            iconSize:[56 - (18 - this.minMapLevel) * 1.25, 89 - (18 - this.minMapLevel) * 2]})});
        this.cluste.addLayer(marker)

        this.addhideList.push(marker);
        marker.stationId = i.item; //设置参数
        marker.on('click', (e)=>{
          let { id, type } = e.target.stationId;
          this.stationId = i.item;
          this.getPointDetail(id, type);
        })
      })
    },

    glInput(e){
      e.preventDefault();
      this.filterMaker = this.markers.map(i=>i.item).filter(item=>item.name.indexOf(this.filterText) != -1);
    },

    searchLoca(item){
      let location = item.location.split(',');
      let l = gcj02towgs84(location[0], location[1])
      this.map.flyTo(BM.latLng(l[0], l[1]), this.configData.maxMapLevel, {animate: true, duration: 2});
    },

     async getPoint(ids){
       //每次进来时先清空原先的坐标点
       this.markers.forEach(item=>{
         item['marker'].remove();
       })
       this.markers = [];
       this.filterMaker = [];

       let res = await mapPoint({ ids });
       let data = res.data.data;

       data.forEach(item=>{
         let img = "";

         if(item.type == 1){
           img = require('./image/7.png');
         }else if(item.type == 2){
           if(item.code == 'soundPost' || item.code == 'intelligent'){
             if(item.status == 1){
               img = require('./image/12.png');
             }else if(item.status == 2){
               img = require('./image/12_2.png');
             }else {
               img = require('./image/12_0.png');
             }
           }else if(item.code == 'townAdapter' || item.code == 'countyAdapte'){
             if(item.status == 1){
               img = require('./image/1.png');
             }else if(item.status == 2){
               img = require('./image/1_2.png');
             }else {
               img = require('./image/1_0.png');
             }
           }else{
             if(item.status == 1){
               img = require('./image/3.png');
             }else if(item.status == 2){
               img = require('./image/3_2.png');
             }else {
               img = require('./image/3_0.png');
             }
           }
         }else if(item.type == 3){
           if(item.code == 13){
             if(item.status == 1){
               img = require('./image/4.png');
             }else {
               img = require('./image/4_0.png');
             }
           }else if(item.code == 14){
             if(item.status == 1){
               img = require('./image/5.png');
             }else {
               img = require('./image/5_0.png');
             }
           }else {
             if(item.status == 1){
               img = require('./image/6.png');
             }else {
               img = require('./image/6_0.png');
             }
           }
         }else {
           if(item.code == 1){
             img = require('./image/9.png');
           }else if(item.code == 2){
             img = require('./image/13.png');
           }else{
             img = require('./image/8.png');
           }
         }


         if(item.location){
           let location = item.location.split(',');
           let l = gcj02towgs84(location[0], location[1])
           let marker = BM.marker([l[0],l[1]],{icon:BM.icon({
               iconUrl:img,
               iconSize:[56 - (18 - this.minMapLevel) * 1.25, 89 - (18 - this.minMapLevel) * 2]
           })})

           this.cluste.addLayer(marker)
           this.map.addLayer(this.cluste);
           /*
           * marker 地图上的坐标点
           * item 坐标点的数据源
           * */
           this.markers.push({
             marker,
             item
           });
           this.filterMaker.push(item)

           marker.stationId = item; //设置参数
           marker.on('click', (e)=>{
             let { id, type } = e.target.stationId;
             this.stationId = item;
             this.getPointDetail(id, type);
           })
         }
       });
    },


    start(){
      startBCast({
        id: this.iconData.id
      }).then(res=>{
        if(res.data.success){
          ElMessage({
            message: res.data.code.message,
            type: 'success',
          })
          let { id, type } = this.stationId;
          this.getPointDetail(id, type);
        }
      })
    },
    stop(){
      stopBCast({
        id: this.iconData.id
      }).then(res=>{
        if(res.data.success){
          ElMessage({
            message: res.data.code.message,
            type: 'success',
          })
          let { id, type } = this.stationId;
          this.getPointDetail(id, type);
        }
      })
    },

    setRegion(code, iszoom = false){
      regionMap({
        regionCode: code
      }).then(res=>{
        //先清除原先的区域
        if(this.polygon) this.polygon.remove();
        let data = eval(res.data.data.code).map(item=>eval(item));
        let ch = data.map(item=>{
          let op = item.map(i=>{
            return gcj02towgs84(i[0], i[1])
          })
          return op
        });
        this.polygon = BM.polygon(ch, { color: '#189165'}).addTo(this.map);
        this.map.fitBounds(this.polygon.getBounds());
        if(iszoom) this.map.setZoom(this.minMapLevel)
      })
    },

    handleNodeClick(data){
      this.filterText = "";
      this.setRegion(data.code);
    },

    flyLineOneInit(_is = true, ebmId = ""){
      if(this.map && this.myEcharts) {
        this.map.removeLayer(this.myEcharts)
      };
      getConfig().then(res=>{
        this.configData = res.data.data;
        getPlayingBroadcastList().then(res=>{
          getFlyLineOne({
            ebmId:ebmId || (res.data.data.length > 0 ? res.data.data[res.data.data.length - 1].ebm_id : "")
          }).then(res=>{
            this.init(this.configData, res.data.data, _is);
            // if(_is) this.getPoint();
          })
        })
      })
    },

    getPointDetail(id, type){
        mapPointDetail({
          id, type
        }).then(res=>{
          this.iconData = res.data.data;
          this.isType = type;
          if(type == 1) { // 如果是摄像头的话
            getWebVideoDetail().then(res => {
              let data = res.data.data;
              if(data.success){
                this.isPlay = true;
                if(data.camera_id == id){
                  this.isXt = false;
                }else{
                  this.isXt = true;
                }
              }else {
                this.isPlay = false;
              }
            })
          }
        })
    },

    init(option, lineData, _is = true){
      let that = this;

      maps.then(()=>{
        BM.Config.HTTP_URL = window.g.map_http_ip;
        // BM.Config.FORCE_HTTPS = true;
        if(_is){
          this.map = BM.map('map', window.g.bigemapKey, {
            crs: BM.CRS.EPSG4326,
            center: [option.latitude, option.longitude],
            zoom: option.defaultMapLevel,
            zoomControl: false,
            maxZoom: option.maxMapLevel,
            minZoom: option.minMapLevel,
            attributionControl: false,//取消右下角水印
          });


          this.cluste = new BM.MarkerClusterGroup({
            showCoverageOnHover: false,//不显示边框
            zoomToBoundsOnClick: true,//点击放大
            removeOutsideVisibleBounds: true,//只显示当前窗口内的内容,
            animateAddingMarkers:true,
          });
        }

        this.minMapLevel = option.defaultMapLevel;

        this.getPoint();
        this.setRegion(option.regionCode, true);

        this.map.on('contextmenu',function(e){
          let dom = that.$refs.menu;
          let { clientX, clientY } = e.originalEvent;
          dom.style.top = clientY + 'px';
          dom.style.left = clientX + 'px'
          that.isMenu = true;
        });

        this.map.on('zoomend',function(e){
          that.markers.forEach(ite=>{
            let img = "";
            if(ite.item.type == 1){
              img = require('./image/7.png');
            }else if(ite.item.type == 2){
              if(ite.item.code == 'soundPost' || ite.item.code == 'intelligent'){
                if(ite.item.status == 1){
                  img = require('./image/12.png');
                }else if(ite.item.status == 2){
                  img = require('./image/12_2.png');
                }else {
                  img = require('./image/12_0.png');
                }
              }else if(ite.item.code == 'townAdapter' || ite.item.code == 'countyAdapte'){
                if(ite.item.status == 1){
                  img = require('./image/1.png');
                }else if(ite.item.status == 2){
                  img = require('./image/1_2.png');
                }else {
                  img = require('./image/1_0.png');
                }
              }else{
                if(ite.item.status == 1){
                  img = require('./image/3.png');
                }else if(ite.item.status == 2){
                  img = require('./image/3_2.png');
                }else {
                  img = require('./image/3_0.png');
                }
              }
            }else if(ite.item.type == 3){
              if(ite.item.code == 13){
                if(ite.item.status == 1){
                  img = require('./image/4.png');
                }else {
                  img = require('./image/4_0.png');
                }
              }else if(ite.item.code == 14){
                if(ite.item.status == 1){
                  img = require('./image/5.png');
                }else {
                  img = require('./image/5_0.png');
                }
              }else {
                if(ite.item.status == 1){
                  img = require('./image/6.png');
                }else {
                  img = require('./image/6_0.png');
                }
              }
            }else {
              if(ite.item.code == 1){
                img = require('./image/9.png');
              }else if(ite.item.code == 2){
                img = require('./image/13.png');
              }else{
                img = require('./image/8.png');
              }
            }

            ite.marker.setIcon(BM.icon({
                iconUrl: img,
                iconSize:[56 - (18 - this.getZoom()) * 1.25, 89 - (18 - this.getZoom()) * 2]}
            ))
          })
        });

        var convertData = function (data) {
          var res = [];
          for (var i = 0; i < data.length; i++) {
            var dataItem = data[i];
            var toCoord =[dataItem.longitude, dataItem.latitude].filter(item=>item);
            var fromCoord = [lineData.start.longitude, lineData.start.latitude]
            if (toCoord.length > 0) {
              res.push({
                coords: [fromCoord , toCoord]
              });
            }
          }
          return res;
        };

        if(Object.keys(lineData).length > 0){
          var Data = lineData.end;
          var color = ['#46bee9', '#FDFF6E'];
          var series = [];
          [[Data]].forEach((item, i)=> {
            series.push(
                {
                  type: 'lines',
                  coordinateSystem: 'leaflet',
                  zlevel: 2,
                  symbol: ['none', 'arrow'],
                  symbolSize: 10,
                  effect: {
                    show: true,
                    period: 5, //箭头指向速度，值越小速度越快
                    trailLength: .1, //特效尾迹长度[0,1]值越大，尾迹越长重
                    symbol: 'arrow',
                    symbolSize: 10
                  },
                  lineStyle: {
                    normal: {
                      color: color[i],
                      width: 1,
                      opacity: 0.2,
                      curveness: 0.3
                    }
                  },
                  data: convertData(item[0])
                },
                {
                  type: 'effectScatter',
                  coordinateSystem: 'leaflet',
                  zlevel: 2,
                  rippleEffect: {
                    brushType: 'stroke'
                  },
                  label: {
                    normal: {
                      show: true,
                      position: 'inside', //显示位置
                      formatter: function(params) { //圆环显示文字
                        return params.data.name;
                      },
                      fontSize: 16,
                      color: "#000000",
                    },
                  },
                  // symbolSize: function (val) {
                  //   return val[2] / 8;
                  // },
                  itemStyle: {
                    normal: {
                      color: color[i]
                    }
                  },
                  data: item[0].map(function (dataItem) {
                    return {
                      name: dataItem.name,
                      value: [ dataItem.longitude, dataItem.latitude ]
                    };
                  })
                });
                // {
                //   type: 'effectScatter',
                //   coordinateSystem: 'leaflet',
                //   zlevel: 2,
                //   rippleEffect: {
                //     brushType: 'stroke'
                //   },
                //   label: {
                //     normal: {
                //       show: true,
                //       position: 'inside', //显示位置
                //       formatter: function(params) { //圆环显示文字
                //         return params.data.name;
                //       },
                //       fontSize: 16,
                //       color: "#000000",
                //     },
                //   },
                //   // symbolSize: function (val) {
                //   //   return val[2] / 8;
                //   // },
                //   itemStyle: {
                //     normal: {
                //       color: color[i]
                //     }
                //   },
                //   data: item[0].map(function (dataItem) {
                //     return {
                //       name: dataItem.name,
                //       value: [ dataItem.longitude, dataItem.latitude ]
                //     };
                //   })
                // }
            // item[0].forEach(dataItem=>{
            //   if(dataItem.longitude && dataItem.latitude){
            //     var myIcon = BM.divIcon({
            //       html: `<div style="color:red;white-space: nowrap;">
            //            ${dataItem.name}
            //          </div>`
            //     });
            //     let l = gcj02towgs84(dataItem.longitude,  dataItem.latitude)
            //     BM.marker([l[0], l[1]], {
            //       // icon: myIcon
            //     }).addTo(this.map);
            //   }
            // })
          });
          var options = {
            tooltip: {
              trigger: 'item'
            },
            series: series
          };
          this.myEcharts = BM.supermap.echartsLayer(options);
          this.myEcharts.addTo(this.map);
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.opacity-enter-active {
  animation: opacity-in .5s;

}
.opacity-leave-active {
  animation: opacity-in .5s reverse;
}

.hello-enter,
.hello-leave-to {
  transform: translateX(25%);
}
.hello-enter-active,
.hello-leave-active {
  transition: 2s linear;
}
/* 进入的终点、离开的起点 */
.hello-enter-to,
.hello-leave {
  transform: translateX(0);
}


.hello2-enter,
.hello2-leave-to {
  transform: translateX(-25%);
}
.hello2-enter-active,
.hello2-leave-active {
  transition: 2s linear;
}
/* 进入的终点、离开的起点 */
.hello2-enter-to,
.hello2-leave {
  transform:0;
}

@keyframes bounceIn{
  0%{opacity:0;transform:scale(.3)}
  50% {opacity: 1;transform:scale(1.05)}
  70%{transform:scale(.9)}
  100%{opacity:1;transform:scale(1)}
}

@keyframes opacity-in {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

::v-deep .bigemap-popup-content-wrapper{
  background: none;
  .bigemap-popup-content{
    padding: 0;
    width: auto !important;
  }
}

::v-deep .bigemap-popup-tip-container{
  display: none;
}

.menu{
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0 1px 1px #888,1px 0 1px #ccc;
  display: flex;
  flex-direction: column;

  >div{
    cursor:pointer;
    padding: 10px;
    font-size: 14px;
    border-bottom: 1px solid rgba(83, 83, 83, .2);
  }
}


.dwDiv{
  overflow: auto;
  z-index: 2004;
  position: absolute;
  top: 55px;
  width: 165px;
  height: 181px;
  background: rgba(#111A10, .8);
  border: 1px solid #1A9F61;
  &::-webkit-scrollbar{
    display: none;
  }
  .not{
    text-align: center;
    display: block;
    color: #bebebe;
    font-size: 14px;
    margin: 30px 0;
  }
  .search-input{
    color: #59B7A4;
    padding: 5px;
    width: 100%;
    background: none;
    font-size: 16px;
    border-bottom: 1px solid #1A9F61;
    &::-webkit-input-placeholder {
      color: #77b9a5;
    }
  }
  .search-li{
    color: #59B7A4;
    font-size: 16px;
    padding: 5px 0;
    &:hover{
      transition: all .1s;
      font-weight: bold;
    }
  }
}

.event-box{
  width: 304px;
  background: #070202;
  border: 1px solid #DF1716;
  animation: bounceIn 1s;
  .event-top{
    background: url("./image/top.png") no-repeat center;
    background-size: 100% 100%;
    width: 100%;
    height: 6px;
  }
  .in-box{
    width: 100%;
    padding: 10px;
    .hed{
      height: 47px;
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #F98989;
      >span{
        margin-left: 11px;
      }
    }
    .infoHed{
      text-align: left;
      font-size: 14px;
      font-weight: bold;
      color: #FFFFFF;
    }
    .infoBody{
      text-align: left;
      margin-bottom: 15px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
    }
  }
}

.dh{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.noce{
  top: 150px;
  transform: translate(-50%, 0);
}

.device-box{
  width: 304px;
  background: #061B16;
  border: 1px solid #1A9F61;
  padding: 10px;
  //max-height: 550px;
  overflow: auto;
  animation: bounceIn 1s;
  &::-webkit-scrollbar{
    display: none;
  }
  .hed{
    height: 47px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #95D7C8;
    .hedImg{
      background: url("./child/image/spq.png") no-repeat center;
      background-size: 100% 100%;
      width: 24px;
      height: 20px;
    }
    >span{
      margin-left: 11px;
    }
  }
  .infoHed{
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
  }
  .infoBody{
    text-align: left;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
  }
}

.test{
  width: 304px;
  background: #061B16;
  border: 1px solid #1A9F61;
  padding: 10px;
  max-height: 420px;
  overflow: auto;
  animation: bounceIn 1s;
  &::-webkit-scrollbar{
    display: none;
  }
  .hed{
    width: 100%;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .hed-right{
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      cursor: pointer;
      .hh,.stop{
        background: url("./child/image/hh.png") no-repeat center;
        background-size: 100% 100%;
        width: 62px;
        height: 24px;
        line-height: 24px;
        margin-left: 10px;
      }
    }
    .hed-left{
      display: flex;
      align-items: center;
      .hedImg{
        background: url("./child/image/737.png") no-repeat center;
        background-size: 100% 100%;
        width: 24px;
        height: 20px;
      }
      .type{
        margin-left: 10px;
        font-size: 14px;
        font-weight: bold;
        color: #95D7C8;
      }
    }
  }
  .video-play{
    width: 100%;
    height: 148px;
  }
  .infoHed{
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
  }
  .infoBody{
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    width: 100%;
    overflow: auto;
  }
}

.riqi{
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #F6FFFE 0%, #BDE0D6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: left;
  border-left: 1px solid #BDE0D6;
  padding-left: 20px;
  margin-left: 20px;
}
.left-shadow{
  position: absolute;
  top: 0;
  left: 0;
  background: url("./image/shadow.png") no-repeat center;
  height: 1080px;
  width: 476px;
  background-size: 100% 100%;
}

.right-shadow{
  position: absolute;
  right: 0;
  top: 0;
  background: url("./image/shadow.png") no-repeat center;
  height: 1080px;
  width: 476px;
  background-size: 100% 100%;
  transform: rotateY(180deg);
}
.topDiv{
  .k1{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    background: url("./image/k1.png") no-repeat center;
    background-size: 100% 100%;
    width: 1920px;
    height: 118px;
    .topRight{
      display: flex;
      align-items: center;
      position: absolute;
      right: 86px;
      top: 54px;
      .sbss,.dw{
        background: url("./image/inputk.png") no-repeat center;
        background-size: 100% 100%;
        width: 110px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        >span{
          font-size: 16px;
          font-weight: bold;
          background: linear-gradient(0deg, #238D6D 0%, #FFFFFF 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-left: 5px;
        }
      }
      .dw{
        width: 145px;
        margin-left: 25px;
      }
    }
    .title{
      display: inline-block;
      margin-top: 20px;
      font-size: 48px;
      font-weight: 400;
      color: #FFFFFF;
      text-shadow: 2px 3px 0px rgba(59,61,65,0.19);
      background: linear-gradient(180deg, #F6FAFF 0%, #CEFFF0 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .timer{
      position: absolute;
      top: 50px;
      left: 75px;
      display: flex;
      align-items: center;
      time{
        font-size: 40px;
        font-weight: 400;
        color: #FFFFFF;
        background: linear-gradient(180deg, #F6FFFE 0%, #BDE0D6 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .k2{
    z-index: 9;
    position: absolute;
    right: 0;
    top: 118px;
    background: url("./image/k4.png") no-repeat center;
    background-size: 100% 100%;
    width: 31px;
    height: calc(1080px - 118px - 187px);
  }

  .k2 >img{
    position: absolute;
    top: 53%;
    left: 59%;
    transform: translate(-50%, -50%);
    width:12px;
    height: 31px;
    cursor: pointer;
  }

  .k3{
    z-index: 9;
    position: absolute;
    left: 0;
    top: 118px;
    background: url("./image/k2.png") no-repeat center;
    background-size: 100% 100%;
    width: 33px;
    height: calc(1080px - 118px - 187px);
  }
  .k3 >img{
    position: absolute;
    top: 55%;
    left: 23%;
    transform: translate(-50%, -50%);
    width:12px;
    height: 31px;
    cursor: pointer;
  }
  .k4{
    z-index: 9;
    position: absolute;
    bottom: 0;
    left: 0;
    background: url("./image/k3.png") no-repeat center;
    background-size: 100% 100%;
    width: 1920px;
    height: 187px;
  }
}
.mainDiv{
  width: 1920px;
  height: 1080px;
}

.myMap{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1080px;
  background: url("./image/bg.png") no-repeat center;
  background-size: 100% 100%;
}
</style>
import axios from "@/api/axios"; // 引用axios

export const getData = (params) => {
    return axios({
        url: "/api/scheduling/strategy/listData",
        method: "post",
        params: params,
    });
};

export const codeInfo = (params) => {
    return axios({
        url: "/api/pub/getCodeInfo",
        method: "post",
        params: params,
    });
};

export const dataSave = (params) => {
    return axios({
        url: "/api/scheduling/strategy/addData",
        method: "post",
        params: params,
    });
};


export const getedit = (params) => {
    return axios({
        url: "/api/scheduling/strategy/editData",
        method: "post",
        params: params,
    });
};


export const dataDel = (params) => {
    return axios({
        url: "/api/scheduling/strategy/del",
        method: "post",
        params: params,
    });
};

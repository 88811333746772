import axios from "@/api/axios"; // 引用axios

export const getPlayingBroadcastList = (params) => {
    return axios({
        url: "/api/jy/big/screen/big/screen/getPlayingBroadcastList",
        method: "post",
        data: params,
    });
};

export const getDeviceCount = (params) => {
    return axios({
        url: "/api/jy/big/screen/getDeviceCount",
        method: "post",
        data: params,
    });
};

export const getConfig = (params) => {
    return axios({
        url: "/api/jy/big/screen/getConfig",
        method: "post",
        data: params,
    });
};

export const getCodeInfo = (params) => {
    return axios({
        url: "/api/jy/big/screen/getCodeInfo",
        method: "post",
        data: params,
    });
};

export const getTodayWarnEventList = (params) => {
    return axios({
        url: "/api/jy/big/screen/getTodayWarnEventList",
        method: "post",
        data: params,
    });
};

export const getFlyLineOne = (params) => {
    return axios({
        url: "/api/jy/big/screen/getFlyLineOne",
        method: "post",
        params: params,
    });
};

export const getFlyLineTwo = (params) => {
    return axios({
        url: "/api/jy/big/screen/getFlyLineTwo",
        method: "post",
        params: params,
    });
};

export const mapPoint = (params) => {
    return axios({
        url: "/api/jy/big/screen/mapPoint",
        method: "post",
        params: params,
    });
};

export const mapPointDetail = (params) => {
    return axios({
        url: "/api/jy/big/screen/mapPointDetail",
        method: "post",
        params: params,
    });
};

export const regionTree = (params) => {
    return axios({
        url: "/api/jy/big/screen/regionTreeById",
        method: "post",
        params: params,
    });
};

export const getByTimeCount = (params) => {
    return axios({
        url: "/api/jy/big/screen/bcast/getByTimeCount",
        method: "post",
        params: params,
    });
};

export const getByNameCount = (params) => {
    return axios({
        url: "/api/jy/big/screen/bcast/getByNameCount",
        method: "post",
        params: params,
    });
};

export const getWebVideoDetail = (params) => {
    return axios({
        url: "/api/jy/big/screen/getWebVideoDetail",
        method: "post",
        params: params,
    });
};

export const stopBCast = (params) => {
    return axios({
        url: "/api/jy/big/screen/stopBCast",
        method: "post",
        params: params,
    });
};

export const startBCast = (params) => {
    return axios({
        url: "/api/jy/big/screen/startBCast",
        method: "post",
        params: params,
    });
};

export const regionMap = (params) => {
    return axios({
        url: "/api/jy/big/screen/regionMap",
        method: "post",
        params: params,
    });
};

export const getPatrolRecordList = () => {
    return axios({
        url: "/api/big/screen/getPatrolRecordList",
        method: "post",
    });
};



<template>
  <div class="bottomDiv">
<!--    <el-checkbox v-model="checked1" size="large" />-->
    <div class="mainDiv">
      <div class="chooseDiv" v-for="(item, index) in btnList" @click="onBtn(index)" :key="index">
        <p>{{ item }}</p>
      </div>
    </div>


    <div class="listDiv">
      <div v-show="idx.includes(0)">
        <el-checkbox-group v-model="checkList.cameraType" @change="changeGroup($event, 'cameraType')">
          <div class="scsc" v-for="(item, index) in codeInfo.cameraType" :key="index">
            <el-checkbox :label="item.code">
              <img src="./image/737.png" />
              <span>{{ item.name }}</span>
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
      <div v-show="idx.includes(1)">
        <el-checkbox-group v-model="checkList.proberType" @change="changeGroup($event, 'proberType')">
          <div class="scsc" v-for="(item, index) in codeInfo.proberType" :key="index">
            <el-checkbox :label="item.code">
              <img :src="require(`./image/${item.code == 13 ? 'yangan' : item.code == 14 ? 'qigan' : 'sq'}.png`)" />
              <span>{{ item.name }}</span>
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
      <div v-show="idx.includes(2)">
        <el-checkbox-group v-model="checkList.resourceType" @change="changeGroup($event, 'resourceType')">
          <div class="scsc" v-for="(item, index) in codeInfo.resourceType" :key="index">
            <el-checkbox :label="item.code">
<!--              <img src="./image/spq.png" />-->
              <img :src="require(`./image/${item.code == 'expander' || item.code == 'soundPost' || item.code == 'intelligent' ? 'zd' :item.code == 'ipMIC' ? 'ip' : 'spq'}.png`)" />
              <span>{{ item.name }}</span>
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
      <div v-show="idx.includes(3)">
        <el-checkbox-group v-model="checkList.warnEventType" @change="changeGroup($event, 'warnEventType')">
          <div class="scsc" v-for="(item, index) in codeInfo.warnEventType" :key="index">
            <el-checkbox :label="item.code">
              <span style="margin-left: 10px">{{ item.name }}</span>
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script>
import { getCodeInfo } from "../../../api/jy";
export default {
  data(){
    return{
      codeInfo:{},
      gl:{
        cameraType:[],
        ameraType: [],
        proberType:[],
        resourceType:[]
      },
      idx: [],
      checkList:{
        warnEventType:[],
        cameraType: [],
        proberType:[],
        resourceType:[]
      },
      btnList:[
          '摄像头',
          '传感器',
          '终端设备',
          '事件类型'
      ]
    }
  },

  methods:{
    onBtn(_index){
      const index = this.idx.indexOf(_index)
      if (index > -1) {
        this.idx.splice(index, 1)
      } else {
        this.idx.push(_index)
      }
    },

    //获取新老数据的差异
    getArrDifference(arr1, arr2){
      return arr1.concat(arr2).filter((v, i, arr) => {
        return arr.indexOf(v) === arr.lastIndexOf(v);
      })
    },


    changeGroup(value, type){
      this.gl[type] = this.getArrDifference(value, this.codeInfo[type].map(item=>item.code));
      this.$emit('ADDHIDE', this.gl[type], value, type);
    }
  },

  mounted() {
    getCodeInfo().then(res=>{
      let data = res.data.data;
      this.codeInfo = data;
      let op = [
        'cameraType',
        'proberType',
        'resourceType',
        'warnEventType'
      ]
      op.forEach(item=>{
        this.checkList[item] = data[item].map(item=>item.code);
      });
    })
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-checkbox{
  height: auto;
}

::v-deep .el-checkbox__label{
  padding: 0;
}
.bottomDiv{
  z-index: 9;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  .listDiv{
    position: absolute;
    bottom: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, 0);
    >div{
      &:nth-child(1){
        transform: translate(-290px, -90px);
      }
      &:nth-child(2){
        transform: translate(-93px, -90px);
      }
      &:nth-child(3){
        transform: translate(100px, -90px);
      }
      &:nth-child(4){
        transform: translate(296px, -0.46875rem);;
      }
      position: absolute;
      margin-right: 7px;
      width: 178px;
      height: 181px;
      background: rgba(#111A10, .7);
      border: 1px solid #1A9F61;
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
      .scsc{
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        color: #FFFFFF;
        padding: 5px;
        img{
          margin: 0 10px;
          width: 24px;
          height: 20px;
        }
      }
    }
  }
  .mainDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 720px;
    .chooseDiv{
      background: url("./image/1263.png");
      background-size: 100% 100%;
      height: 70px;
      width: 130px;
      p{
        font-size: 20px;
        font-weight: bold;
        transform: translateY(-10px);
        color: #D8FFF6;
        background: linear-gradient(0deg, #238D6D 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

}
</style>
import axios from "@/api/axios"; // 引用axios

export const getData = (params) => {
    return axios({
        url: "/api/home/bcast/bcastList",
        method: "post",
        params: params,
    });
};

export const broadcastStatisticsVO = (params) => {
    return axios({
        url: "/api/home/getTodaysBroadcastStatisticsVO",
        method: "post",
        params: params,
    });
};


export const getNumber = (params) => {
    return axios({
        url: "/api/home/getNumberOfBroadcastingEquipment",
        method: "post",
        params: params,
    });
};


export const getVideo = (params) => {
    return axios({
        url: "/api/home/screen/getVideo",
        method: "post",
        params: params,
    });
};

export const getByNameCount = (params) => {
    return axios({
        url: "/api/home/bcast/getByNameCount",
        method: "post",
        params: params,
    });
};


export const getByTimeCount = (params) => {
    return axios({
        url: "/api/home/bcast/getByTimeCount",
        method: "post",
        params: params,
    });
};


export const getCount = (params) => {
    return axios({
        url: "/api/home/source/count2",
        method: "post",
        params: params,
    });
};


export const mapCount = (params) => {
    return axios({
        url: "/api/home/device/getHomeMapCount",
        method: "post",
        params: params,
    });
};


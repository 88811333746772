<template>
  <div class="title">
    <div class="text">{{ TEXT }}</div>
  </div>
</template>

<script>
export default {
  props:{
    TEXT:{
      type: String
    }
  },
}
</script>
<style lang="scss" scoped>
.title{
  background: url("./image/title.png") no-repeat center;
  background-size: 100% 100%;
  width: 396px;
  height: 38px;
  text-align: left;
  .text{
    font-size: 24px;
    font-weight: 400;
    font-style: italic;
    color: #FFFFFF;
    display: inline-block;
    margin-left: 40px;
  }
}
</style>
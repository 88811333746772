<template>
  <div class="rightDiv">
    <div class="rtop">
      <Title TEXT="实时广播" />
      <vue-seamless-scroll v-if="isscroll" :data="broadcastList" :class-option="optionSingleHeightTime" class="rtop-main">
        <div class="gb-list" v-for="(item, index) in broadcastList" :key="index">
          <i class="icon"></i>
          <div class="gbDiv">
            <div class="gb-info">
              <span class="gbtime">{{ item.create_time }}</span>
              <span class="gbmid">编号 {{ item.ebm_id }}</span>
              <span class="gbbom">{{ item.instructionSource }}</span>
            </div>
            <div class="jibie" :class="item.event_level == 1 ? 'acitve1' : item.event_level == 2 ? 'acitve2' : item.event_level == 3 ? 'acitve3' : 'acitve4'">{{item.event_level == 1 ? '严重' : item.event_level == 2 ? '重大' : item.event_level == 3 ? '较大' : '一般'}}</div>
            <span class="ebmid" v-if="codeList.length > 0 && item.bcast_type">{{ codeList.length > 0 && codeList.filter(e=>e.code == item.bcast_type)[0].name}}({{item.event_type}})</span>
          </div>
        </div>
      </vue-seamless-scroll>
    </div>


    <div class="rmid">
      <Title TEXT="告警信息" />
<!--      :class-option="optionSingleHeightTime"-->
      <div class="gjList not" style="margin: 10px 0;">
        <div class="gjtitle">
          <div class="timage">
            <div class="gjtext">
              今日告警
              <div class="gjy">{{ eventList.count }}</div>
            </div>
          </div>
        </div>
      </div>
      <vue-seamless-scroll v-if="isWarn" :class-option="optionSingleHeightTime" :data="eventScroll" class="gjDiv">
        <!--        <div class="gjDiv">-->
        <div class="gjList" v-for="(item, index) in eventScroll" :key="index">
          <i class="gjicon"></i>
          <div class="gjtitle two">
            <span style="font-size: 15px;font-weight: 400;color: #95D7C8;text-align: left">{{ item.createTime }} {{ item.name }}</span>
<!--            <img v-if="item.url" :src="item.url">-->
            <el-image
                :append-to-body='true'
                style="width: 100px; height: 60px"
                v-if="item.url"
                :src="item.url"
                :preview-src-list="[item.url]"
                fit="cover"
            />
          </div>
        </div>
        <!--        </div>-->

      </vue-seamless-scroll>
    </div>

    <div class="rbom">
      <Title TEXT="播控数据" />
      <div class="bkDiv">
        <div class="bkTop">
          <div class="topOnce">
            <span v-if="nameCount.length > 0">{{ nameCount[0].num }}</span>
            <img src="./image/rb3.png" />
          </div>

          <div class="topOnce">
            <span v-if="nameCount.length > 0">{{ nameCount[1].num }}</span>
            <img src="./image/rb2.png" />
          </div>

          <div class="topOnce">
            <span v-if="nameCount.length > 0">{{ nameCount[2].num }}</span>
            <img src="./image/rb1.png" />
          </div>
        </div>

        <div class="bkChart" id="bkChart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPlayingBroadcastList, getTodayWarnEventList, getByTimeCount, getByNameCount } from "../../../api/jy";
import { codeInfo } from "../../../api/devops/strategy";
import Title from './Title'
import * as echarts from "echarts";
import {computed} from "vue";
export default {
  components:{
    Title
  },

  data(){
    return{
      isWarn:true,
      isscroll:true,
      socket: null,
      nameCount:[],
      broadcastList:[],
      eventList:[],
      eventScroll:[],
      codeList:[],
    }
  },

  computed:{
    optionSingleHeightTime(){
      return{
        openWatch: true,
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    },
  },

  methods:{
    setSocket(){
      this.socket = new WebSocket(`${window.g.socketUrl}/websocket/topic`);
      // this.socket = new WebSocket(`ws://192.168.3.163:9889/websocket/topic`);
      this.socket.onopen = ()=>{
        console.log('连接成功')
      };
      this.socket.onmessage = (e) => {
        let data;
        try {
          data = JSON.parse(e.data);
        }catch (err){
          console.log(err)
        }
        if(data && data.messageType == 4 && data.broadcastType == 1){
          //实时广播 开启
          this.isscroll = false;
          setTimeout(()=>{
            this.isscroll = true;
          },0)
          this.broadcastList.push(data.beanDetail[0]);
          this.$parent.$parent.flyLineOneInit(false, data.beanDetail[0].ebm_id);
        }

        if(data && data.messageType == 4 && data.broadcastType == 2){
          //实时广播 关闭
          this.isscroll = false;
          setTimeout(()=>{
            this.isscroll = true;
          },0)
          this.broadcastList.splice(this.broadcastList.findIndex(itm=>itm.ebm_id == data.beanDetail[0].ebm_id), 1);
          this.$parent.$parent.flyLineOneInit(false, this.broadcastList.length > 0 ? this.broadcastList[this.broadcastList.length - 1].ebm_id : "");
        }

        if(data && data.messageType == 2 && data.operationType == 1){
          this.$emit('ADDPOINT', data.beanDetail[0])
          //新增点位
        }

        if(data && data.messageType == 2 && data.operationType == 2){
          this.$emit('EDITPOINT', data.beanDetail[0])
          //编辑点位
        }

        if(data && data.messageType == 2 && data.operationType == 3){
          this.$emit('DELPOINT', data.beanDetail[0])
          //删除点位
        }
      };
    },

    bkInit(data){
      var textLabel = {
        position: 'top',
        color: '#fff',
        fontSize: 10,
        formatter: '{c}',
      }
      let int = 1;
      var chartDom = document.getElementById("bkChart");
      var myChart = echarts.init(chartDom);
      let option = {
        tooltip: {
          trigger: 'axis',
          backgroundColor:'rgba(58,247,159,0.5)',
          textStyle:{
            color:"#fff"
          },
          axisPointer: {
            type: 'shadow'
          },
        },
        legend: {
          show: true,
          itemWidth:12,
          itemHeight:3,
          itemGap: 80,
          icon: "roundRect",
          textStyle: {
            align: 'left',
            verticalAlign: 'middle',
            rich: {
              name: {
                color: '#95D7C8',
                fontSize: 14,
              },
            },
          },
          formatter: (name) => {
            return `{name|${name}}`;
          },
        },
        grid: {
          top: '20%',
          left: '3%',
          right: '5%',
          bottom: '5%',
          containLabel: true,
        },
        xAxis: {
          boundaryGap: false,
          type: 'category',
          axisLine: {
            show: true,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: '#90CFC1'
          },
          axisTick: {
            show: true,
          },
          data: ['00-04时', '04-08时', '08-12时', '12-16时', '16-20时', '20-24时'],
        },
        yAxis: {
          type: 'value',
          interval:100,
          // min: 0,
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: '#90CFC1'
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: '演练',
            type: 'bar',
            label: {
              show:false,
              ...textLabel
            },
            barWidth: '8',
            data: [ data[2].time0_4, data[2].time4_8, data[2].time8_12, data[2].time12_16, data[2].time16_20, data[2].time20_24],
            itemStyle: {
              color: "#FF3C22"
            }
          },
          {
            name: '日常',
            type: 'bar',
            label: {
              show:false,
              ...textLabel
            },
            barWidth: '8',
            data: [ data[1].time0_4, data[1].time4_8, data[1].time8_12, data[1].time12_16, data[1].time16_20, data[1].time20_24],
            itemStyle: {
              color: "#FF7800"
            }
          },
          {
            name: '应急',
            label: {
              show:false,
              ...textLabel
            },
            type: 'bar',
            barWidth: '8',
            data: [ data[0].time0_4, data[0].time4_8, data[0].time8_12, data[0].time12_16, data[0].time16_20, data[0].time20_24],
            itemStyle: {
              color: "#F3E114"
            }
          },
        ]
      };
      option && myChart.setOption(option);
    },
  },

  mounted() {
    this.setSocket();

    getByNameCount({ type:1 }).then(res=>{
      this.nameCount = res.data.data;
    })

    getByTimeCount().then(res=>{
      this.bkInit(res.data.data)
    })

    codeInfo({type:1}).then(res=>{this.codeList = res.data.data})


    setInterval(()=>{
      getTodayWarnEventList().then(res=>{
        this.isWarn = false;
        setTimeout(()=>{
          this.isWarn = true;
        },0)
        this.eventList = res.data.data;
        this.eventScroll = this.eventList.list;
      })
    },1000 * 60 * 2)
    getTodayWarnEventList().then(res=>{
      this.eventList = res.data.data;
      this.eventScroll = this.eventList.list;
    })
    getPlayingBroadcastList().then(res=>{
      this.broadcastList = res.data.data;
    })
  }
}
</script>
<style lang="scss" scoped>
$divWidth:396px; //统一内容框宽度
@mixin displayCenter(){
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin textSet($size, $weight, $color){
  font-size: $size;
  font-weight: $weight;
  color: $color;
}
  .rightDiv{
    position: absolute;
    top: 120px;
    right: 40px;
    .rtop{
      width: $divWidth;
      .rtop-main{
        width: 100%;
        height: 240px;
        padding: 5px 5px;
        overflow: hidden;
        margin: 30px 0;
        .gb-list{
          position: relative;
          width: 100%;
          padding-bottom: 17px;
          border-left: 1px #3AF79F dashed;
          .icon{
            display: block;
            width: 10px;
            height: 10px;
            background: url("./image/icon.png");
            background-size: 100% 100%;
            position: absolute;
            top: -5px;
            left: -5px;
          }

          .gbDiv{
            display: flex;
            justify-content: space-between;
            margin-left: 23px;
            border-bottom: 1px solid #2A6A57;
            position: relative;
            .gb-info{
              display: flex;
              flex-direction: column;
              text-align: left;
              .gbtime{
                @include textSet(16px, 400, #FFFFFF);
                margin-bottom: 13px;
              }
              .gbmid{
                @include textSet(14px, bold,rgba(255, 255, 255, 0.6));
                margin-bottom: 11px;
              }
              .gbbom{
                @include textSet(16px, 400, rgba(255, 255, 255, 0.7));
                margin-bottom: 13px;
              }
            }
            .jibie{
              width: 46px;
              height: 24px;
              font-size: 14px;
              font-weight: bold;
              line-height: 24px;
              &.acitve1{
                color: rgba(#FF3C22, 0.6);
                background: #1B0805;
                border-left: 3px solid #FF3C22;
                border-right: 3px solid #FF3C22;
              }
              &.acitve2{
                color: rgba(#FF7800, 0.6);
                background: #1B0805;
                border-left: 3px solid #FF7800;
                border-right: 3px solid #FF7800;
              }
              &.acitve3{
                color: rgba(#F0E316, 0.6);
                background: #1C1906;
                border-left: 3px solid #F3E114;
                border-right: 3px solid #F3E114;
              }
              &.acitve4{
                color: rgba(#0BE5FF, 0.6);
                background: #09142A;
                border-left: 3px solid #0BE5FF;
                border-right: 3px solid #0BE5FF;
              }
            }

            .ebmid{
              position: absolute;
              right: 0;
              bottom: 12px;
              font-size: 16px;
              font-weight: 400;
              color: rgba(255, 255, 255, 0.6);
            }
          }
        }
      }
    }

    .gjList{
      position: relative;
      width: 100%;
      padding-bottom: 17px;
      border-left: 1px #D9C81F dashed;
      .gjicon{
        display: block;
        width: 10px;
        height: 10px;
        background: url("./image/icon2.png");
        background-size: 100% 100%;
        position: absolute;
        top: -5px;
        left: -5px;
      }
      .gjtitle{
        width: 317px;
        margin-left: 20px;
        &.two{
          display: flex;
          flex-direction: column;
          >img{
            margin-top: 13px;
          }
        }
        .timage{
          width: 317px;
          height: 20px;
          background: url("./image/k.png") no-repeat center;
          background-size: 100% 100%;
          transform: translate(0, 8px);
          position: relative;
          .gjtext{
            position: absolute;
            top: -12px;
            left: 20px;
            font-size: 16px;
            font-weight: 400;
            color: #E6F0F5;
            display: flex;
            align-items: center;
            .gjy{
              margin-left: 10px;
              width: 23px;
              height: 23px;
              background: #5F1913;
              border: 1px solid #E53E30;
              border-radius: 50%;
              font-size: 12px;
              font-weight: 400;
              line-height: 23px;
              color: #EFEFEF;
            }
          }
        }
      }
    }

    .not{
      padding: 0;
      border: none;
    }

    .rmid{
      width: $divWidth;
      .gjDiv{
        width: 100%;
        height: 195px;
        padding: 5px 5px;
        margin: 0 0 20px 0;
        overflow: hidden;
      }
    }

    .rbom{
      position: relative;
      z-index: 9;
      width: $divWidth;
      .bkDiv{
        width: 100%;
        height: 260px;
        .bkTop{
          width: 100%;
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          font-size: 18px;
          font-weight: bold;
          color: #3AF79F;
          .topOnce{
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .bkChart{
          width: 100%;
          height: 140px;
        }
      }
    }
  }
</style>